import * as React from 'react';

const Recover: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g
            fill="none"
            stroke={props.color || 'rgba(108, 115, 127, 1)'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        >
            <path d="M12 5H5M12 5a8 8 0 1 1-8 8M8 2 5 5M8 8 5 5" />
            <path d="M15 13.75h-3.808V9.834" />
        </g>
        <path fill="none" d="M0 0h24v24H0z" />
    </svg>
);

export default React.memo(Recover);
