import { MenuTypes } from '@/services/menu/types';

export enum Status {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    HIDDEN = 'HIDDEN',
    SNOOZED = 'SNOOZED',
}

export enum Publish {
    PENDING = 'pending',
    PUBLISHED = 'published',
    FAILED = 'failed',
    UNPUBLISHED = 'unpublished',
}

// Product Related Types
export enum ProductType {
    PRODUCT = 'PRODUCT',
    ADDITIVE_MODIFIER = 'ADDITIVE_MODIFIER',
    BUNDLE = 'BUNDLE',
    ADDITIVE = 'ADDITIVE',
}

export enum GroupManagementType {
    MODIFIER_GROUP = 'MODIFIER_GROUP',
    UPSELL = 'UPSELL',
}

// Type Aliases
export type TabValue = 'all' | ProductType;
export type ProductTypesValue = Exclude<ProductType, never>;
export type GroupManagementValue = Exclude<GroupManagementType, never>;

// Status Color Mapping
export const STATUS_COLORS: Record<Status, 'error' | 'success' | 'warning' | 'secondary'> = {
    [Status.ENABLED]: 'success',
    [Status.DISABLED]: 'error',
    [Status.HIDDEN]: 'warning',
    [Status.SNOOZED]: 'secondary',
};

// Publish Status Color Mapping
export const PUBLISH_COLORS: Record<Publish, 'success' | 'error' | 'warning'> = {
    [Publish.PENDING]: 'warning',
    [Publish.PUBLISHED]: 'success',
    [Publish.FAILED]: 'error',
    [Publish.UNPUBLISHED]: 'error',
};

// Option Types
interface Option<T extends string> {
    label: string;
    value: T;
}

// Status Options
export const statusOptions: Option<Status | 'all'>[] = [
    { label: 'All', value: 'all' },
    { label: 'Active', value: Status.ENABLED },
    { label: 'Disabled', value: Status.DISABLED },
    { label: 'Hidden', value: Status.HIDDEN },
];

// Tab Options
export const tabOptions: Option<TabValue>[] = [
    { label: 'All', value: 'all' },
    { label: 'Products', value: ProductType.PRODUCT },
    { label: 'Modifiers', value: ProductType.ADDITIVE_MODIFIER },
    { label: 'Bundles', value: ProductType.BUNDLE },
    { label: 'Additives', value: ProductType.ADDITIVE },
];

// Group Management Options
export const groupManagementOptions: Option<GroupManagementValue>[] = [
    { label: 'Modifier Groups', value: GroupManagementType.MODIFIER_GROUP },
    { label: 'Upsell Groups', value: GroupManagementType.UPSELL },
];

// Product Type Options
export const productTypesOptions: Option<ProductTypesValue>[] = [
    { label: 'Product', value: ProductType.PRODUCT },
    { label: 'Modifier', value: ProductType.ADDITIVE_MODIFIER },
    { label: 'Bundle', value: ProductType.BUNDLE },
    { label: 'Additive options', value: ProductType.ADDITIVE },
];

// Menu Type Options
export const menuTypesOptions: Option<MenuTypes>[] = [
    { label: 'Delivery', value: 'DELIVERY' },
    { label: 'Pickup', value: 'PICKUP' },
    { label: 'Eat-In', value: 'EAT_IN' },
    { label: 'Delivery and Pickup', value: 'DELIVERY_AND_PICKUP' },
];

// Publish Options
export const publishOptions: Option<Publish | 'all'>[] = [
    { label: 'All', value: 'all' },
    { label: 'Published', value: Publish.PUBLISHED },
    { label: 'Failed', value: Publish.FAILED },
];
