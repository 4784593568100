import * as React from 'react';

const Archived: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g fill="none">
            <path d="M0 0h24v24H0Z" />
            <path
                stroke={props.color || 'rgba(108, 115, 127, 1)'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M8 18h11a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v11a2 2 0 0 0 2 2"
            />
            <path
                stroke={props.color || 'rgba(108, 115, 127, 1)'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M21 13h-3.434a1 1 0 0 0-.857.486l-.417.696a1 1 0 0 1-.857.486h-3.868a1 1 0 0 1-.857-.486l-.417-.696A1 1 0 0 0 9.434 13H6"
            />
            <path
                stroke={props.color || 'rgba(108, 115, 127, 1)'}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M3 7v12a2 2 0 0 0 2 2h12"
            />
        </g>
    </svg>
);

export default React.memo(Archived);
