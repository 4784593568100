import React, { FC, useState } from 'react';
import { Box, Typography } from '@mui/material';
import Link from 'next/link';
import { useTranslation } from '@/hooks/translations';
import { Add } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { menuTypesOptions } from '../data';
import QSRModal from '../Modal';
import QSRButton from '../Button';
import styles from './index.module.scss';

interface IProps {
    redirect?: boolean;
}

const CreateNewMenuModal: FC<IProps> = (props) => {
    const router = useRouter();
    const [openMenuModal, setOpenMenuModal] = useState(false);
    const { t } = useTranslation();

    const handleClick = () => {
        if (props.redirect) {
            router.push('/menus/create/');
            return;
        }
        setOpenMenuModal(true);
    };

    return (
        <>
            <QSRButton onClick={handleClick} startIcon={<Add fontSize="medium" />} variant="contained">
                {t('Create New Menu')}
            </QSRButton>

            <QSRModal
                open={openMenuModal}
                closeIcon
                onClose={() => setOpenMenuModal(false)}
                title={t('Create New Menu')}
                subtitle={t('Select option from the below list that you want to create new menu')}
            >
                <Box mt={2}>
                    {menuTypesOptions.map((tab) => (
                        <Box key={tab.value} className={styles.list}>
                            <Link href="/menus/create/">
                                <Typography>{tab.label}</Typography>
                            </Link>
                        </Box>
                    ))}
                </Box>
            </QSRModal>
        </>
    );
};

export default CreateNewMenuModal;
