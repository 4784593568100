import React, { FC } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import withTheme from '@/components/QSRComponents/withTheme';
import styles from './index.module.scss';

interface IProps extends DialogProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    subtitle?: string;
    closeIcon?: boolean;
    children: React.ReactNode;
}

const QSRModal: FC<IProps> = ({ open, onClose, title, subtitle, closeIcon = false, children, ...rest }) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                className: styles.dialog,
            }}
            {...rest}
        >
            {(title || subtitle || closeIcon) && (
                <DialogTitle component="div">
                    <Box display="flex" alignItems="baseline" justifyContent="space-between">
                        <Box>
                            {title && (
                                <Typography variant="h5" className={styles.dialogTitle} mb={1}>
                                    {title}
                                </Typography>
                            )}
                            {subtitle && (
                                <Typography variant="body2" className={styles.dialogSubTitle}>
                                    {subtitle}
                                </Typography>
                            )}
                        </Box>
                        {closeIcon && (
                            <IconButton onClick={onClose} edge="end" aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        )}
                    </Box>
                </DialogTitle>
            )}
            <DialogContent>{children}</DialogContent>
        </Dialog>
    );
};

export default withTheme(QSRModal);
