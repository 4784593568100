import React, { createContext, useState, useContext, useMemo } from 'react';
import { MenuResponse, MenuProductResponse } from '@/services/menu/types';
import { ISelectedItems } from '@/components/QSRComponents/DataGrid';

interface IQSRContext {
    isRefreshing: boolean;
    triggerRefresh: () => void;

    isCloneMenuOpen: boolean;
    toggleCloneMenu: () => void;

    selectedMenus: ISelectedItems<MenuResponse>;
    selectedProducts: ISelectedItems<MenuProductResponse>;

    handleSelectMenu: (selected: ISelectedItems<MenuResponse>) => void;
    handleSelectProduct: (selected: ISelectedItems<MenuProductResponse>) => void;
}

type IProps = {
    children: React.ReactNode;
};

const QSRContext = createContext<IQSRContext | undefined>(undefined);

export const QSRContextProvider: React.FC<IProps> = ({ children }) => {
    const [isCloneMenuOpen, setIsCloneMenuOpen] = useState<boolean>(false);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [selectedMenus, setSelectedMenus] = useState<ISelectedItems<MenuResponse>>({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
    });
    const [selectedProducts, setSelectedProducts] = useState<ISelectedItems<MenuProductResponse>>({
        allSelected: false,
        selectedCount: 0,
        selectedRows: [],
    });

    const triggerRefresh = () => {
        setIsRefreshing((prev) => !prev);
    };

    const toggleCloneMenu = () => {
        setIsCloneMenuOpen((prev) => !prev);
    };

    const handleSelectMenu = (selected: ISelectedItems<MenuResponse>) => {
        setSelectedMenus(selected);
    };

    const handleSelectProduct = (selected: ISelectedItems<MenuProductResponse>) => {
        setSelectedProducts(selected);
    };

    const value = useMemo(
        () => ({
            isRefreshing,
            triggerRefresh,
            isCloneMenuOpen,
            toggleCloneMenu,
            selectedMenus,
            selectedProducts,
            handleSelectMenu,
            handleSelectProduct,
        }),
        [isRefreshing, isCloneMenuOpen, selectedMenus, selectedProducts],
    );

    return <QSRContext.Provider value={value}>{children}</QSRContext.Provider>;
};

export const useQSRContext = (): IQSRContext => {
    const context = useContext(QSRContext);
    if (!context) {
        throw new Error('useQSRContext must be used within a QSRContextProvider');
    }
    return context;
};
