import axios from '@/config/axios';
import { ICategoryData, IMenuData, IProductData } from '@/components/QSRComponents/Form/types';
import { MenuCategoryResponse } from '@/services/menu/types';
import { IUpsell } from '@/views/Menus/components/Form/FormComponents/UpsellGroupField';
import { transformError } from '..';
import { CategoryTypeEnum, ICategoryParams, IMenuParams, IProductParams } from './types';

class NewMenuService {
    public static getInstance() {
        if (!this.instance) {
            this.instance = new NewMenuService();
        }

        return this.instance;
    }

    private static instance: NewMenuService;

    public getProducts(restaurantId: string, params: IProductParams) {
        return axios
            .get(`/vendor/${restaurantId}/management/product`, {
                params,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public exportProducts(restaurantId: string, params: IProductParams) {
        return axios
            .get(`/vendor/${restaurantId}/management/product`, {
                params,
                responseType: 'blob',
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getProductById(restaurantId: string, id: string) {
        return axios
            .get(`/vendor/${restaurantId}/management/product/`, {
                params: {
                    id,
                    page: 0,
                    limit: 1,
                },
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteProduct(restaurantId: string, productIds: string[]) {
        return axios
            .delete(`/vendor/${restaurantId}/management/product/`, {
                data: {
                    productIds,
                },
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public updateProduct(restaurantId: string, productIds: string[], data: any) {
        return axios
            .post(
                `/vendor/${restaurantId}/management/product/bulk`,
                productIds.map((id) => {
                    return {
                        ...data,
                        id,
                    };
                }),
            )
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createProduct(restaurantId: string, data: IProductData) {
        return axios
            .post(`/vendor/${restaurantId}/management/product`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public uploadProduct(restaurantId: string, file: FormData) {
        return axios
            .post(`/vendor/${restaurantId}/management/product`, file, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getMenus(restaurantId: string, params: IMenuParams) {
        return axios
            .get(`/vendor/${restaurantId}/management/menu`, {
                params,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getMenuById(restaurantId: string, params: IMenuParams) {
        return axios
            .get(`/vendor/${restaurantId}/management/menu`, {
                params,
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteMenu(restaurantId: string, menuIds: string[]) {
        return axios
            .delete(`/vendor/${restaurantId}/management/menu`, {
                data: {
                    menuIds,
                },
            })
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    // private baseurl = 'https://7138-88-240-96-253.ngrok-free.app/v1';

    public createManagement(restaurantId: string, data: IMenuData) {
        return axios
            .post(`/vendor/${restaurantId}/management/menu`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public bulkCreateMenu(restaurantId: string, data: any) {
        return axios
            .post(`/vendor/${restaurantId}/management/menu/bulk`, data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public editCategoryProductRelation(restaurantId: string, categoryId: string, productIds: string[]) {
        return axios
            .put(`/vendor/${restaurantId}/management/category/${categoryId}`, {
                productIds,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getCategoriesProduct(restaurantId: string, params: IProductParams) {
        return axios
            .get(`/vendor/${restaurantId}/management/category/product`, {
                params,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getCategories(restaurantId: string, params: ICategoryParams) {
        return axios
            .get(`/vendor/${restaurantId}/management/category`, {
                params,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getCategoryById(restaurantId: string, id: string) {
        return axios
            .get(`/vendor/${restaurantId}/management/category`, {
                params: {
                    id,
                    page: 0,
                    limit: 1,
                },
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public deleteMenuCategory(restaurantId: string, categoryIds: string[], menuId?: string) {
        return axios
            .delete(`/vendor/${restaurantId}/management/category`, {
                data: {
                    categoryIds,
                    menuId,
                },
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public createMenuCategory(
        restaurantId: string,
        data: ICategoryData,
        menuId?: string,
        type: CategoryTypeEnum = CategoryTypeEnum.CATEGORY,
    ) {
        return axios
            .post(`/vendor/${restaurantId}/management/category`, {
                ...data,
                menuId,
                type,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public updateCategory(
        restaurantId: string,
        data: MenuCategoryResponse,
        menuId?: string,
        type: CategoryTypeEnum = CategoryTypeEnum.CATEGORY,
    ) {
        return axios
            .post(`/vendor/${restaurantId}/management/category/bulk`, [
                {
                    ...data,
                    menuId,
                    type,
                },
            ])
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public editProductCategoryRelation(
        restaurantId: string,
        productId: string,
        categoryIds?: string[],
        upsell?: IUpsell,
    ) {
        return axios
            .put(`/vendor/${restaurantId}/management/product/${productId}`, {
                categoryIds,
                upsell,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    public getProductCategoryList(restaurantId: string, params: ICategoryParams) {
        return axios
            .get(`/vendor/${restaurantId}/management/product/category`, {
                params,
            })
            .then((res) => res.data)
            .then((res) => res.data)
            .catch((err) => {
                throw transformError(err);
            });
    }

    // public getProducts(restaurantId: string) {
    //     return axios
    //         .get(`/vendor/${restaurantId}/management/product`)
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }
    //
    // public createProduct(restaurantId: string, data: any) {
    //     return axios
    //         .post(`/vendor/${restaurantId}/management/product`, data)
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }
    //
    // public deleteProduct(restaurantId: string) {
    //     return axios
    //         .delete(`/vendor/${restaurantId}/management/product`)
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }
    //
    // public bulkCreateProduct(restaurantId: string, data: any) {
    //     return axios
    //         .post(`/vendor/${restaurantId}/management/product/bulk`, data)
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }
    //
    // public updateOrder(restaurantId: string, type: string, data: any) {
    //     return axios
    //         .put(`/vendor/${restaurantId}/management/order/${type}`, data)
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }
    //
    // public migrateProduct(restaurantId: string) {
    //     return axios
    //         .get(`/vendor/${restaurantId}/management/product/migration`)
    //         .then((res) => res.data)
    //         .catch((err) => {
    //             throw transformError(err);
    //         });
    // }
}

export default NewMenuService;
