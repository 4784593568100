import * as React from 'react';
import { useMemo, useRef } from 'react';
import { useRouter } from 'next/router';
import { useUserContext } from '@/contexts/user';
import { AppBar, Toolbar, Box, useMediaQuery } from '@mui/material';
import { CustomButton, HeaderPageTitle } from '@/components/common';
import SettingsButton from '@/components/SettingsButton';
import { styled, SxProps } from '@mui/material/styles';
import { SocketConnector } from '@/contexts/socket';
import { useRestaurantContext } from '@/contexts/restaurant';
import { useSidebarRoutes } from '@/hooks/useSiderbarRoutes';
import { routes as links } from '@/constants/routes';
import { onPushEvent } from '@/services/lib/gtm';
import { clearLocalStorage } from '@/config/axios';
import Head from 'next/head';
import EventBus from '@/config/event-handler';
import { VendorRole } from '@/constants/roleRestrictions';
import { CachedRounded } from '@mui/icons-material';
import { useNavbarContext } from '@/contexts/navbar';
import { useTranslation } from '@/hooks/translations';
import QSRButton from '@/components/QSRComponents/Button';
import Stack from '@mui/material/Stack';
import QSRButtonMenu from '@/components/QSRComponents/ButtonMenu';
import Download from '@/components/QSRComponents/Icons/Download';
import Reload from '@/components/QSRComponents/Icons/Reload';
import Export from '@/components/QSRComponents/Icons/Export';
import Archived from '@/components/QSRComponents/Icons/Archived';
import Recover from '@/components/QSRComponents/Icons/Recover';
import Rotate from '@/components/QSRComponents/Icons/Rotate';
import Duplicate from '@/components/QSRComponents/Icons/Duplicate';
import Brush from '@/components/QSRComponents/Icons/Brush';
import CreateNewMenuModal from '@/components/QSRComponents/NewMenuModal';
import CreateNewProductModal from '@/components/QSRComponents/NewProductModal';
import NewMenuService from '@/services/NewMenu/menuService';
import { useSnackbar } from 'notistack';
import { useQSRContext } from '@/contexts/qsr';
import CreateNewGroupModal from '@/components/QSRComponents/NewGroupModal';
import HamburgerMenuIcon from '../HamburgerMenuIcon';
import AccountMenu from './AccountMenu';
import LanguageSelectorWithFlags from './LanguageSelectorWithFlags';
import QbaDownloadModal from './QbaDownloadModal';

const useNavbarStyles = () => {
    const navBar: SxProps = {
        display: 'flex',
        gridTemplateColumns: 'repeat(3, minmax(0, 1fr));',
    };

    const pageTitle: SxProps = {
        justifyContent: 'space-around',
    };

    const AvatarContainer: SxProps = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    };

    return { navBar, pageTitle, AvatarContainer };
};

const Nav = styled(AppBar)(({ theme }) => ({
    backgroundColor: '#fff',
    color: theme.palette.text.primary,
    boxShadow: 'none',
    borderBottom: '1px #E5E7EB solid',
}));

const tableViewPaths = [links.TABLE_VIEW, links.VPOS];
const qsrViewPaths = [links.QSR_ORDERS];
const productsPaths = [links.PRODUCTS];
const groupManagementPaths = [links.GROUP_MANAGEMENT];
const menuPaths = [links.MENUS];

export default function Navbar() {
    const { enqueueSnackbar } = useSnackbar();
    const routes = useRouter();
    const newMenuService = NewMenuService.getInstance();
    const { toggleCloneMenu, selectedMenus } = useQSRContext();
    const { t } = useTranslation('common');
    const { toggleRefresh, refresh } = useNavbarContext();
    const { pathname } = routes;
    const { getRoutes } = useSidebarRoutes();
    const { user, setUser } = useUserContext();
    const { restaurant, setRestaurant } = useRestaurantContext();
    const { navBar, AvatarContainer } = useNavbarStyles();
    const logout = () => {
        setUser(null);
        setRestaurant(null);
        SocketConnector.getInstance().destroy();
        clearLocalStorage();
        EventBus.dispatch('logout');
        onPushEvent('user_logged_out');
    };
    const containerRef = useRef(null);

    const getTitle = () => {
        const routeToPageTitle = getRoutes(user);
        const routeArray = [...routeToPageTitle.general, ...routeToPageTitle.settings];
        return routeArray.find((route) => route.link === routes.pathname)?.text;
    };

    const [isTableView, withRefresh] = useMemo(() => {
        return [tableViewPaths.includes(pathname), qsrViewPaths.includes(pathname)];
    }, [pathname]);

    const enableShiftMaskforVendorAnalyst =
        (user?.userData?.role === VendorRole.VendorAnalyst && restaurant?.config?.enableShiftMaskforVendorAnalyst) ||
        false;

    const switchLanguageByFlag = restaurant?.config?.switchLanguageByFlag || false;

    const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down(800), {
        defaultMatches: true,
        noSsr: false,
    });

    const MenuIconAndTitle = useMemo(() => {
        if (!enableShiftMaskforVendorAnalyst && isMobile) {
            return (
                <Box sx={{ display: 'flex' }}>
                    <HamburgerMenuIcon withLogo={false} />
                    <HeaderPageTitle text={getTitle()} />
                </Box>
            );
        }
        return <HeaderPageTitle text={getTitle()} />;
    }, [isMobile, enableShiftMaskforVendorAnalyst, routes.pathname, t]);

    const handleExport = async () => {
        if (!restaurant) return;
        try {
            const res = await newMenuService.exportProducts(restaurant.id, {
                page: 0,
                limit: 10,
                file: 'csv',
            });
            const blob = new Blob([res], { type: 'text/csv' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = 'file.csv';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            enqueueSnackbar(t('Exported successfully'), { variant: 'success' });
        } catch (e) {
            console.error(e);
            enqueueSnackbar(t('Failed to export'), { variant: 'error' });
        }
    };

    const showQbaDownloadModalByRole = useMemo(() => {
        return [VendorRole.VendorAdmin, VendorRole.VendorAnalyst, VendorRole.VendorFinance].includes(
            user?.userData?.role,
        );
    }, [user]);

    return (
        <Nav position="static" ref={containerRef}>
            <Head>
                <title>{getTitle()} | Qlub</title>
            </Head>
            <Toolbar sx={{ ...navBar, justifyContent: 'space-between', paddingLeft: '1rem', paddingRight: '1rem' }}>
                {MenuIconAndTitle}
                <Box sx={AvatarContainer}>
                    {isTableView && <SettingsButton onClick={() => onPushEvent('user_click_on_filter_icon')} />}
                    {withRefresh && (
                        <CustomButton
                            endIcon={<CachedRounded />}
                            size="large"
                            sx={{
                                color: 'primary.main',
                                margin: '0 12px',
                            }}
                            onClick={() => {
                                toggleRefresh(!refresh);
                            }}
                        >
                            {t('Refresh')}
                        </CustomButton>
                    )}
                    {groupManagementPaths.includes(pathname) && (
                        <Stack direction="row" alignItems="center" spacing={2} mr={2}>
                            <CreateNewGroupModal />
                            <QSRButtonMenu
                                items={[
                                    {
                                        label: t('Export'),
                                        icon: <Export width={20} height={20} />,
                                        onClick: () => handleExport(),
                                    },
                                    {
                                        label: t('Import'),
                                        icon: <Download width={20} height={20} />,
                                        onClick: () => console.log('Edit'),
                                    },
                                    {
                                        label: t('Sync from POS'),
                                        icon: <Reload width={20} height={20} />,
                                        onClick: () => console.log('Edit'),
                                    },
                                ]}
                            />
                        </Stack>
                    )}
                    {productsPaths.includes(pathname) && (
                        <Stack direction="row" alignItems="center" spacing={2} mr={2}>
                            <CreateNewProductModal />
                            <QSRButtonMenu
                                items={[
                                    {
                                        label: t('Export'),
                                        icon: <Export width={20} height={20} />,
                                        onClick: () => handleExport(),
                                    },
                                    {
                                        label: t('Import'),
                                        icon: <Download width={20} height={20} />,
                                        onClick: () => console.log('Edit'),
                                    },
                                    {
                                        label: t('Sync from POS'),
                                        icon: <Reload width={20} height={20} />,
                                        onClick: () => console.log('Edit'),
                                    },
                                ]}
                            />
                        </Stack>
                    )}
                    {menuPaths.includes(pathname) && (
                        // TODO: Implement PUBLISH disabled, create unless the restaurant is integrated.
                        <Stack direction="row" alignItems="center" spacing={2} mr={2}>
                            <QSRButton
                                disabled={selectedMenus.selectedCount === 0}
                                variant="outlined"
                                startIcon={<Export width={20} height={20} color="#7D00D4" />}
                            >
                                {t('Publish Menu')}
                            </QSRButton>
                            <CreateNewMenuModal redirect />
                            <QSRButtonMenu
                                items={[
                                    {
                                        label: t('Archived'),
                                        icon: <Archived width={20} height={20} />,
                                        onClick: () => console.log('Edit'),
                                    },
                                    {
                                        label: t('Recover Menu'),
                                        icon: <Recover width={20} height={20} />,
                                        onClick: () => console.log('Edit'),
                                    },
                                    {
                                        label: t('Refresh the Inventory'),
                                        icon: <Rotate width={20} height={20} />,
                                        onClick: () => console.log('Edit'),
                                    },
                                    {
                                        label: t('Clone'),
                                        icon: <Duplicate width={20} height={20} />,
                                        disabled: selectedMenus.selectedCount === 0,
                                        onClick: () => toggleCloneMenu(),
                                    },
                                    {
                                        label: t('Truncate'),
                                        color: '#981410',
                                        icon: <Brush width={20} height={20} color="#981410" />,
                                        onClick: () => console.log('Edit'),
                                    },
                                ]}
                            />
                        </Stack>
                    )}
                    {switchLanguageByFlag && <LanguageSelectorWithFlags />}
                    {showQbaDownloadModalByRole && <QbaDownloadModal />}
                    <AccountMenu user={user} restaurant={restaurant} logout={logout} />
                </Box>
            </Toolbar>
        </Nav>
    );
}
