import * as React from 'react';
import withTheme from '@/components/QSRComponents/withTheme';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import styles from './index.module.scss';

interface ButtonMenuItem {
    label: string;
    onClick: (event?: React.MouseEvent<HTMLElement>) => void;
    icon?: React.ReactNode;
    color?: string;
    disabled?: boolean;
}

interface ButtonMenuProps {
    items: ButtonMenuItem[];
    buttonColor?: string;
}

const QSRButtonMenu: React.FC<ButtonMenuProps> = ({ items, buttonColor }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (
        onClick: (event?: React.MouseEvent<HTMLElement>) => void,
        event: React.MouseEvent<HTMLElement>,
    ) => {
        onClick(event);
        handleClose();
    };

    return (
        <>
            <IconButton
                id="dynamic-button-menu"
                aria-controls={open ? 'dynamic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                className={open ? styles.active : styles.inactive}
            >
                <MoreVert sx={{ color: buttonColor }} />
            </IconButton>
            <Menu
                id="dynamic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'dynamic-button-menu',
                    className: styles.menu,
                }}
                slotProps={{
                    paper: {
                        className: styles.paper,
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {items.map((item, index) => (
                    <MenuItem
                        key={index}
                        onClick={(event) => handleItemClick(item.onClick, event)}
                        className={styles.menuItem}
                        disabled={item.disabled}
                    >
                        {item.icon && (
                            <ListItemIcon sx={{ color: item.disabled ? 'gray' : item.color }}>{item.icon}</ListItemIcon>
                        )}
                        <ListItemText sx={{ color: item.disabled ? 'gray' : item.color }}>{item.label}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default withTheme(QSRButtonMenu);
