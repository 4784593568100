import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from '@/hooks/translations';
import { Add } from '@mui/icons-material';
import { useRouter } from 'next/router';
import { productTypesOptions } from '../data';
import QSRModal from '../Modal';
import QSRButton from '../Button';
import styles from './index.module.scss';

const CreateNewProductModal = () => {
    const [openModal, setOpenModal] = useState(false);
    const router = useRouter();
    const { t } = useTranslation();

    const handlePush = (url: string) => {
        setOpenModal(false);
        router.push(`/products/create/?type=${url}`);
    };

    return (
        <>
            <QSRButton onClick={() => setOpenModal(true)} startIcon={<Add fontSize="medium" />} variant="contained">
                {t('Create New Product')}
            </QSRButton>

            <QSRModal
                open={openModal}
                closeIcon
                onClose={() => setOpenModal(false)}
                title={t('Create New Product')}
                subtitle={t('Select option from the below list that you want to create new product')}
            >
                <Box mt={2}>
                    {productTypesOptions.map((tab) => (
                        <Box key={tab.value} className={styles.list} onClick={() => handlePush(tab.value)}>
                            <Typography>{tab.label}</Typography>
                        </Box>
                    ))}
                </Box>
            </QSRModal>
        </>
    );
};

export default CreateNewProductModal;
