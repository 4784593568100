import * as React from 'react';

const Rotate: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g
            fill="none"
            stroke={props.color || 'rgba(108, 115, 127, 1)'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
        >
            <path d="M19.488 4.639v3.536h-3.535 3.535" />
            <path d="M19.027 8.175A8 8 0 1 0 20 12" />
        </g>
        <path fill="none" d="M0 0h24v24H0Z" />
    </svg>
);

export default React.memo(Rotate);
