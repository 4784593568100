import { createContext, ReactNode, useState, useContext, useMemo } from 'react';

interface NavbarContextType {
    isSettingsOpen: boolean;
    setIsSettingsOpen: (open: boolean) => void;
    refresh: boolean;
    toggleRefresh: (refresh: boolean) => void;
    showAgentConnectionAlert: boolean;
    setShowAgentConnectionAlert: (show: boolean) => void;
}

const NavbarContext = createContext<NavbarContextType>({
    isSettingsOpen: false,
    setIsSettingsOpen: (_: boolean) => undefined,
    refresh: false,
    toggleRefresh: (_: boolean) => undefined,
    showAgentConnectionAlert: false,
    setShowAgentConnectionAlert: (_: boolean) => undefined,
});

type Props = {
    children: ReactNode;
};

export function NavbarContextProvider({ children }: Props) {
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
    const [refresh, toggleRefresh] = useState<boolean>(false);
    const [showAgentConnectionAlert, setShowAgentConnectionAlert] = useState<boolean>(false);

    const value = useMemo(
        () => ({
            isSettingsOpen,
            setIsSettingsOpen,
            refresh,
            toggleRefresh,
            showAgentConnectionAlert,
            setShowAgentConnectionAlert,
        }),
        [isSettingsOpen, refresh, showAgentConnectionAlert],
    );

    return <NavbarContext.Provider value={value}>{children}</NavbarContext.Provider>;
}

export const useNavbarContext = () => useContext(NavbarContext);
