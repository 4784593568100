import * as React from 'react';

const Brush: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path fill="none" d="M24 24H0V0h24Z" />
        <path
            fill="none"
            stroke={props.color || 'rgba(108, 115, 127, 1)'}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M19.338 7.169 17.219 9.29M16.825 4.652l-2.119 2.122M15.817 15.817 8.183 8.183M6.414 12.78l.774.774a.5.5 0 0 1-.023.729l-3.453 3.042a2.1 2.1 0 0 0-.097 3.06h0a2.1 2.1 0 0 0 3.06-.097l3.042-3.453a.5.5 0 0 1 .729-.023l.774.774a2 2 0 0 0 2.828 0l6.313-6.313a2.18 2.18 0 0 0 0-3.085l-4.55-4.55a2.18 2.18 0 0 0-3.084 0L6.414 9.953a2 2 0 0 0 0 2.828"
        />
    </svg>
);

export default React.memo(Brush);
