import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import clsx from 'clsx';
import withTheme from '../withTheme';
import styles from './index.module.scss';

interface QSRButtonProps extends ButtonProps {}

const modifyIcon = (icon: React.ReactNode, disabled?: boolean) => {
    if (!React.isValidElement(icon)) return icon;

    const iconElement = icon as React.ReactElement<React.SVGProps<SVGSVGElement>>;
    return React.cloneElement(iconElement, {
        color: disabled ? '#BDBDBD' : iconElement.props.color,
    });
};

const QSRButton: React.FC<QSRButtonProps> = ({ disabled, startIcon, endIcon, children, ...props }) => (
    <Button
        className={clsx(styles.button, { [styles.disabled]: disabled })}
        disabled={disabled}
        startIcon={modifyIcon(startIcon, disabled)}
        endIcon={modifyIcon(endIcon, disabled)}
        {...props}
    >
        {children}
    </Button>
);

export default withTheme(QSRButton);
