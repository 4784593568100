export enum MenuFetchingModeEnum {
    NORMAL = 'NORMAL',
    POS = 'POS',
    PREVIEW = 'PREVIEW',
}

export enum DirectionEnum {
    ASC = 'ASC',
    DESC = 'DESC',
}

export enum MenuRelationEnum {
    SUMMARIZE = 'SUMMARIZE',
    PRODUCT = 'PRODUCT',
    CATEGORY = 'CATEGORY',
    CATEGORY_PRODUCT = 'CATEGORY_PRODUCT',
}

export interface IMenuParams {
    page: number;
    mode: MenuFetchingModeEnum;
    limit: number;
    relationType?: MenuRelationEnum;
    type?: string;
    search?: string;
    id?: string;
}

export enum ProductTypeEnum {
    PRODUCT = 'PRODUCT',
    ADDITIVE = 'ADDITIVE',
    BUNDLE = 'BUNDLE',
    MODIFIER = 'MODIFIER',
    ADDITIVE_MODIFIER = 'ADDITIVE_MODIFIER',
}

export interface IProductParams {
    page: number;
    limit: number;
    search?: string;
    publishStatus?: string;
    type?: ProductTypeEnum;
    file?: 'csv';
    categoryId?: string;
}

export enum CategoryTypeEnum {
    BUNDLE_MODIFIER_GROUP = 'BUNDLE_MODIFIER_GROUP',
    MODIFIER_GROUP = 'MODIFIER_GROUP',
    ADDITIVE_MODIFIER_GROUP = 'ADDITIVE_MODIFIER_GROUP',
    CATEGORY = 'CATEGORY',
    UPSELL = 'UPSELL',
}

export interface ICategoryParams {
    type: CategoryTypeEnum;
    menuId?: string;
    page: number; // 1
    limit: number; // 1000 only for type "cat"
    file?: 'csv';
    id?: string;
    search?: string;
    productId?: string;
}
